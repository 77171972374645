import React, { useState } from "react";
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import Hanrei from "../containers/hanrei4"

const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;

return (
<Hanrei
  frontmatter={post.frontmatter}
/>
  );
};

export default BlogPostTemplate;



export const pageQuery = graphql`
  query hanrei_geoA2($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
title_height_name
hanrei_height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
title_color_name
hanrei_color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_color
        hanrei2_color
        hanrei3_color
        hanrei4_color
        hanrei1_name
        hanrei2_name
        hanrei3_name
        hanrei4_name
        height_ratio
        kokudo_hanrei1_name
        kokudo_hanrei2_name
        kokudo_hanrei3_name
        kokudo_hanrei4_name
        kokudo_height_ratio
        year1
        year2
        year3
        year4
        year5
        try
        min
        ratio
        shikii1
        shikii2
        shikii3
        csvmode

        kml1_color
        kml2_color
        kml3_color
        kml4_color

        kokudo_min
        kokudo_ratio
        kokudo_shikii1
        kokudo_shikii2
        kokudo_shikii3
        tenkei_lon
        tenkei_lat
        tenkei_height
        tenkei_heading
        tenkei_pitch
        tenkei_roll

      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
title_height_name
hanrei_height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
title_color_name
hanrei_color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
            year1
            year2
            year3
            year4
            year5
            try
            min
            ratio
            shikii1
            shikii2
            shikii3
            csvmode
            kml1_color
            kml2_color
            kml3_color
            kml4_color
    
            kokudo_min
            kokudo_ratio
            kokudo_shikii1
            kokudo_shikii2
            kokudo_shikii3

        tenkei_lon
        tenkei_lat
        tenkei_height
        tenkei_heading
        tenkei_pitch
        tenkei_roll
    
          }
        }
      }
    }
  }
`;

